import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchMemberList, addMember, updateMember, updateIntegra, updateBalance } from "@/api/member";
import Pagination from "@/components/Pagination";
import { transSourceType, transMemberLevel } from "@/utils/util";
import { validateMobile, isNum } from "@/utils/validate";
export default {
  name: "equipmentList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectCount: 0,
      selectList: [],
      rules: {
        mobile: [{
          required: true,
          validator: validateMobile,
          trigger: "blur"
        }],
        count: [{
          validator: isNum,
          trigger: "blur"
        }],
        gives: [{
          validator: isNum,
          trigger: "blur"
        }]
      },
      // member
      memberMap: {
        update: "编辑会员",
        create: "添加会员"
      },
      memberTitle: "",
      memberDialog: false,
      dialogForm: {
        mobile: "",
        card_no: ""
      },
      // recharge
      rechargeMap: {
        update: "余额",
        create: "积分"
      },
      rechargeTitle: "",
      rechargeDialog: false,
      rechargeForm: {
        change: 1,
        count: "",
        remark: "",
        member_id: "",
        balance: "",
        points: "",
        gives: ""
      },
      totalNum: 0
    };
  },
  created: function created() {
    this.searchForm.keyword = this.$route.query ? this.$route.query.mobile : '';
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchMemberList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.totalNum = response.meta.total_money;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      console.log("添加会员");
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          console.log(_this2.dialogForm);
          _this2.btnLoading = true;
          addMember(_this2.dialogForm).then(function (res) {
            console.log(res);

            _this2.$message({
              type: "success",
              message: "添加会员成功"
            });

            _this2.memberDialog = false;
            _this2.btnLoading = false;

            _this2.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this2.memberDialog = false;
            _this2.btnLoading = false;

            _this2.$message({
              type: "error",
              message: error.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log("修改会员");
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          console.log(_this3.dialogForm);
          _this3.btnLoading = true;
          updateMember(_this3.dialogForm).then(function (res) {
            console.log(res);

            _this3.$message({
              type: "success",
              message: "修改会员成功"
            });

            _this3.memberDialog = false;
            _this3.btnLoading = false;

            _this3.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this3.memberDialog = false;
            _this3.btnLoading = false;

            _this3.$message({
              type: "error",
              message: error.data["message"]
            });
          });
        }
      });
    },
    resetMember: function resetMember() {
      this.dialogForm = {
        mobile: "",
        card: ""
      };
    },
    handleAddBtn: function handleAddBtn() {
      this.resetMember();
      this.memberTitle = "create";
      this.memberDialog = true;
    },
    handleEditBtn: function handleEditBtn(row) {
      this.dialogForm = Object.assign({}, row);
      this.memberTitle = "update";
      this.memberDialog = true;
    },
    handleIntegralBtn: function handleIntegralBtn(row) {
      var _this4 = this;

      // 积分
      this.rechargeTitle = "create";
      this.rechargeDialog = true;
      this.rechargeForm.member_id = row.id;
      this.rechargeForm.points = row.points;
      this.rechargeForm.count = "";
      this.rechargeForm.remark = "";
      this.$nextTick(function () {
        _this4.$refs["rechargeForm"].clearValidate();
      });
    },
    handleBalanceBtn: function handleBalanceBtn(row) {
      var _this5 = this;

      // 余额
      this.rechargeTitle = "update";
      this.rechargeDialog = true;
      this.rechargeForm.member_id = row.id;
      this.rechargeForm.balance = row.balance;
      this.rechargeForm.count = "";
      this.rechargeForm.remark = "";
      this.rechargeForm.gives = "";
      console.log(row);
      this.$nextTick(function () {
        _this5.$refs["rechargeForm"].clearValidate();
      });
    },
    handleIntegra: function handleIntegra() {
      var _this6 = this;

      console.log("");
      var count = this.rechargeForm.count;

      if (count !== "" && count !== null && Number(count)) {
        updateIntegra(this.rechargeForm).then(function (res) {
          console.log(res);
          _this6.rechargeDialog = false;

          _this6.$message({
            type: "success",
            message: "积分修改成功"
          });

          _this6.getTableData();
        }).catch(function (err) {
          console.log(err);

          _this6.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      } else {
        console.log("请输入数值");
        this.$message({
          type: "warning",
          message: "请输入充值数目"
        });
      }
    },
    handleBalance: function handleBalance() {
      var _this7 = this;

      var count = this.rechargeForm.count;

      if (count !== "" && count !== null && Number(count)) {
        updateBalance(this.rechargeForm).then(function (res) {
          console.log(res);
          _this7.rechargeDialog = false;

          _this7.$message({
            type: "success",
            message: "余额修改成功"
          });

          _this7.getTableData();
        }).catch(function (err) {
          console.log(err);

          _this7.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      } else {
        console.log("请输入数值");
        this.$message({
          type: "warning",
          message: "请输入充值数目"
        });
      }
    },
    transSourceType: transSourceType,
    transMemberLevel: transMemberLevel
  }
};